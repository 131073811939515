import React, { useEffect, useState, createRef } from "react";
import axios from "axios";
import ReactGA from "react-ga";
import { Row, Col, Container, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import SweetAlert from "react-bootstrap-sweetalert";
import styled from "styled-components";
import parse from "html-react-parser";
import { Helmet } from "react-helmet";
import ContactUsSection from "./components/contactus";
import IntroSection from "./components/intoSection";
import LoanStatus from "./components/loanStatusSection";
import ProductSection from "./components/productSection/productSection";
import Banner from "./components/wholesaleBanner";
import Forms from "./components/Form/Form";
import Inputs from "./components/Inputs";
import Dropdown from "./components/Dropdown";
import styles from "./styles.module.css";
import States from "./states/states";
import Loader from "./components/LoadingIndicator";
import PinValidator from "./components/pincodeValidator";
import Modal from "./components/Modal";
import RadioButton from "./components/RadioButton";
import Consent from "./components/CommunicationConsent";
import CustomNavbar from "./components/Navbar";
import CustomFooter from "./components/FooterNew/index";
// import siteData from "./json";
import "./App.css";

const SmallFormWrapper = styled.div`
  & :where(button[type="submit"], input[type="submit"]) {
    background: #00aae4;
    border: 2px solid #00aae4;
    box-sizing: border-box;
    border-radius: 20px;
    width: 200px;
    padding: 3px 5px !important;
    margin: 20px auto !important;
  }

  &
    :where(
      input[type="text"],
      input[type="number"],
      input[type="password"],
      input[type="email"]
    ) {
    font-size: 14px !important;
  }

  & .ratesSmallForm {
    min-width: 300px;
  }

  & p {
    color: #bf1650;
    width: 100%;
    text-align: left;
    margin-left: 5px;
    font-size: 12px;
    margin-bottom: 0;
  }
`;

const FormWrapper = styled.div`
  & form {
    max-width: 600px;
    padding: 30px;
    margin: 20px;
    background: #ffffff;
    border: 2px solid #083f88;
    box-sizing: border-box;
    box-shadow: 0px 0px 30px 10px rgba(0, 170, 228, 0.2);
    border-radius: 10px;
  }

  & :where(button[type="submit"], input[type="button"], input[type="submit"]) {
    background: #00aae4;
    border: 2px solid #00aae4;
    box-sizing: border-box;
    border-radius: 20px;
    width: 200px;
    padding: 3px 5px !important;
  }
`;

function WholesaleLending() {
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState("");
  const [Downloads, setDownloads] = useState([]);
  const [session, setSession] = useState([]);
  const [dynamicContent, setDynamicContent] = useState({});
  const [selectedState, setSelectedState] = useState("");
  const [, setSelectedPincode] = useState("");
  const [rateSheetLoading, setRateSheetLoading] = useState(false);
  const [, SetCity] = useState("");
  const [formShow, setFormShow] = useState(true);
  const [downloadError, setDownloadError] = useState(false);
  const [productShow, setProductShow] = useState(false);
  const [selectedFileType, setSelectedFileType] = useState(0);
  const [consent, setConsent] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  // const goBackToFirstPage = () => setShowConsenSection(false);

  const [wholesaleURL, setWholesaleURL] = useState(null);
  const [showConsenSection, setShowConsenSection] = useState(false);
  const handleProductClose = () => setProductShow(false);
  // const handleProductShow = () => setProductShow(true);
  const resetMessage = () => setMessage("");
  const resetDownload = () => setDownloads([]);
  const resetSession = () => setSession([]);
  const currentURL = window.location.href;

  useEffect(() => {
    const fetchGuidelines = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_WHOLESALEANDCORRESPONDENT_GUIDELINES_URL);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const jsonFromEnv = await response.json();
        const wholesaleURL = jsonFromEnv[0].urlWholesale;
        setWholesaleURL(wholesaleURL);
      } catch (error) {
        console.error('Error fetching guidelines:', error);
      }
    };

    fetchGuidelines();
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await axios.get(
          process.env.REACT_APP_BROKER_SITE_DATA
        );

        const [siteData] = data.filter((item) =>
          currentURL.toLowerCase().includes(item.domain.toLowerCase())
        );
        // const siteData = data[0];
        if (siteData) {
          setDynamicContent(siteData);
        }
      } catch (err) {
        console.log(err);
      }
    };
    getData();
  }, [currentURL]);

  const handleConsentCheck = (e) => {
    setConsent(e.target.checked);
  };
  // const handleconsentClick2 = () => {
  //   setConsent(false);
  // };
  // const handleconsentClick1 = () => {
  //   setConsent(true);
  // };

  const sendFormData = async (val) => {
    const requestURL = `${process.env.REACT_APP_WHOLESALE_BROKER_MAIL}`;

    let body = val;
    try {
      const mailData = await axios.post(requestURL, body);
      if (mailData) {
        setMessage(mailData);
      }
    } catch (err) {
      console.log(err);
      setMessage("error");
    }
  };
  const sendLoginCreds = async (obj) => {
    const requestURL = `${process.env.REACT_APP_SUNSOFT_SESSION}`;
    const body = JSON.stringify(obj);

    try {
      if (obj.username && obj.password) {
        const userData = await axios.post(requestURL, body, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (userData) {
          setSession(userData.data);
        }
      }
    } catch (err) {
      if (+err?.response?.status === 403) {
        setSession("error");
      } else {
        setSession("connectivityError");
      }
    } finally {
      setRateSheetLoading(false);
    }
  };

  const requestDoc = async (val, token) => {
    const requestURL_BinaryData = `${process.env.REACT_APP_RATESHEET_DOC}`;

    const body = {
      parseRateGrid: false,
      companyCode: val.companyCode,
      fileType: val.fileType,
    };

    try {
      const postResult = await axios.post(
        requestURL_BinaryData,
        JSON.stringify(body),
        {
          headers: {
            "Content-Type": "application/json",
            "Session-Token": token,
          },
        }
      );

      if (postResult) {
        setDownloads(postResult.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fileSchema = yup.lazy((value) => {
    let companyCode;

    if (session.externalUser === false) {
      companyCode = yup
        .string()
        .required("Company Code is required")
        // .test('len', 'Must be at least 4 characters', val => val.length > 3)
        .typeError("Company Code is required");
    } else {
      companyCode = yup.mixed().notRequired();
    }

    return yup.object().shape({
      fileType: yup
        .string()
        .required("Please select a file type")
        .test("val", "Must be checked", (val) => val.toString() !== 0)
        .typeError("Please select a file type"),

      companyCode: companyCode,
    });
  });

  // const fileSchema = yup.object().shape({

  // });

  const loginSchema = yup.object().shape({
    username: yup
      .string()
      .required("SunSoft User ID is required")
      // .test('len', 'Must be at least 4 characters', val => val.length > 3)
      .typeError("SunSoft User ID is required"),
    password: yup
      .string()
      .required("SunSoft Password is required")
      // .test('len', 'Must be at least 4 characters', val => val.length > 3)
      .typeError("SunSoft Password is required"),
  });

  const BrokerSchema = yup.lazy((value) => {
    let zipcode;

    /**SWMC-1001: autofill has data inside value.Zipcode,
     * hence assign current zip code to validation , if and only if
     * state is present and gets validated from PinValidator
     */
    let validatedZipCode =
      PinValidator(value.Zipcode) === value.State ? value.Zipcode : "";

    if (value.State === "" || value.State === "Select a State*") {
      zipcode = yup
        .string()

        .required("Please select a State first")
        .test(
          "len",
          "Please select a State first",
          (val) => val.toString().length <= 0
        );
    } else {
      zipcode = yup
        .string()
        .trim()
        .required("Please enter a Valid Zipcode")
        .typeError("Please enter a valid Zipcode")
        .matches(/^[0-9]+$/, "Enter numbers only")
        .test(
          "len",
          "Zipcode must be 5 characters",
          (val) => val.toString().length === 5
        )
        .test(
          "val",
          "Zipcode does not match selected state",
          // val => val.toString() === selectedPincode.toString(),
          (val) => val.toString() === validatedZipCode.toString()
        );
    }

    return yup.object().shape({
      Name: yup
        .string()
        .required("Name is required")
        .typeError("Name is required")
        .matches(/^[_A-zA-Z]*((-|\s)*[_A-zA-Z])*$/g, " "),
      Company_Name: yup.string().required("Company Name is required"),
      Company_Address: yup.string().required("Company Address is required"),
      City: yup
        .string()
        .required("City is required")
        .test("len", "Enter a Valid City", (val) => val.toString().length > 2)
        .matches(/^[a-zA-Z ]*$/, "Enter Alphabets only")
        .typeError("Enter Valid City"),
      State: yup
        .string()
        .required("State is required")
        .test(
          "value",
          "Please select a State",
          (val) => val && val.toString() !== " "
        )
        .typeError("Please select a State"),

      Zipcode: zipcode,
      email: yup
        .string()
        .email("Please enter a valid Email")
        .required("Email is required")
        .typeError("must be a valid mail Id"),
      Telephone: yup
        .number()
        .required("Telephone is required")
        .typeError("Please enter a valid Number")
        .test(
          "len",
          "Must be 10 characters",
          (val) => val.toString().length === 10
        ),
    });
  });

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setIsLoading(false);
    if (message !== "") {
      setShowModal(false);
      setTimeout(() => {
        setShowModal(false);
      }, 3000);
      setTimeout(() => {
        resetMessage();
      }, 3500);
    }
  }, [message]);

  useEffect(() => {
    setRateSheetLoading(false);
    if (session === "error") {
      setShowModal(true);
      setTimeout(() => {
        setShowModal(false);
      }, 3000);
      setTimeout(() => {
        resetSession();
      }, 3500);
    }
    // alert(session)
  }, [session]);

  function download(fileUrl, fileName) {
    var a = document.createElement("a");
    a.href = fileUrl;
    a.setAttribute("download", fileName);
    a.click();
  }

  function base64ToArrayBuffer(base64) {
    base64 = base64.replace(/^data:([^;]+);base64,/gim, "");
    var binaryString = atob(base64);
    var len = binaryString.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }

  useEffect(() => {
    if (Downloads.fileContent) {
      let data = { ...Downloads };
      if (!data?.error) {
        let content = data.fileContent;
        let fileName = data.fileName;
        let type = fileName.toLowerCase().includes("pdf") ? "pdf" : "xlsx";

        const arrBuffer = base64ToArrayBuffer(content);

        const blob = new Blob([arrBuffer], { type: "application/" + type });

        const url = URL.createObjectURL(blob);

        download(url, fileName);
        // Release the object URL
        URL.revokeObjectURL(url);
      } else {
        let msg = { ...Downloads };
        setErrorMessage(msg.message);
        setDownloadError(true);
        setTimeout(() => {
          setDownloadError(false);
        }, 3000);
      }
      resetDownload();
      setFormShow(true);
    }
  }, [Downloads]);

  const trackButton = createRef();

  const handleBrokerScroll = () => {
    trackButton.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  const { register, handleSubmit, errors, getValues, reset } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(BrokerSchema),
  });

  const onSubmit = (data, e) => {
    if (e) {
      if (!showConsenSection) {
        const formData = new FormData();
        const newData = { ...data };
        newData.TCPA_Consent_Given = consent ? "Yes" : "No";
        formData.append("postData", newData);

        if (data != null) {
          setShowModal(false);
          // sendFormData(formData);
          //setIsLoading(true);
          setShowConsenSection(true);
        }
      } else {
        e.target.reset();
        reset({
          Name: "",
          Company_Name: "",
          Company_Address: "",
          City: "",
          Zipcode: "",
          Telephone: "",
          email: "",
        });
        const formData = new FormData();
        const newData = { ...data };
        newData.TCPA_Consent_Given = consent ? "Yes" : "No";

        formData.append("postData", JSON.stringify(newData));

        if (data != null) {
          setTimeout(() => {
            setShowModal(true);
          }, 700);
          sendFormData(formData);
          setIsLoading(true);
          setShowConsenSection(false);
          setConsent(true);
        }
      }
    }
  };

  const {
    register: register2,
    errors: errors2,
    handleSubmit: handleSubmit2,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(loginSchema),
  });

  const onSubmit2 = (data, e) => {
    if ((data, e)) {
      e.target.reset();
      // alert(JSON.stringify(data))
      sendLoginCreds({ username: data.username, password: data.password });
      setRateSheetLoading(true);
    }
  };

  const {
    register: register3,
    errors: errors3,
    handleSubmit: handleSubmit3,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(fileSchema),
  });

  const onSubmit3 = (data, e) => {
    if (data && e) {
      e.target.reset();
      if (data.companyCode) {
        data.companyCode = data.companyCode.toUpperCase();
      }
      requestDoc(data, session.token);
      setFormShow(false);
    }
  };

  const closeDownloadSweetAlert = () => {
    setDownloadError(false);
  };

  const closeSweetAlert = () => {
    setShowModal(false);
  };

  const changeState = (evt) => {
    setSelectedState(evt.target.value);
    setSelectedPincode("");
  };
  let state;
  const validZip = (ele) => {
    state = PinValidator(ele.target.value);
    if (selectedState === state) {
      setSelectedPincode(ele.target.value);
    }
  };
  const ValidCity = (ele) => {
    const numbers = /^([^0-9]*)$/;

    if (
      ele.target.value.match(numbers) !== null &&
      ele.target.value.length > 2
    ) {
      SetCity(ele.target.value);
    } else {
      SetCity("");
    }
  };
  const fileTypeChange = (ele) => {
    if (ele.target.value === "PDF") {
      setSelectedFileType(0);
    } else {
      setSelectedFileType(1);
    }
  };

  return (
    <article>
      {dynamicContent && (
        <Helmet>
          <meta charSet="utf-8" />
          <title>{dynamicContent.title}</title>
          <meta name="description" content={dynamicContent.description} />
          <meta name="keywords" content={dynamicContent.keywords} />
          <meta
            property="og:url"
            content={`https://${window.location.hostname}`}
          />
          <meta property="og:type" content="website" />
          <meta
            name="title"
            property="og:title"
            content={dynamicContent.title}
          />
          <meta
            name="image"
            property="og:image"
            content="https://resources.swmc.com/swmc-images/Your-True-Home-Is-Always-Within.jpg"
          />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="600" />
          <meta
            name="description"
            property="og:description"
            content={dynamicContent.description}
          />
          <meta name="twitter:card" content="summary" />
          <meta
            name="twitter:url"
            content={`https://${window.location.hostname}`}
          />
          <meta name="twitter:title" content={dynamicContent.title} />
          <meta
            name="twitter:description"
            content={dynamicContent.description}
          />
          <meta
            name="twitter:image"
            content="https://resources.swmc.com/swmc-images/Your-True-Home-Is-Always-Within.jpg"
          />
        </Helmet>
      )}

      <section className={styles.pageContainer}>
        <CustomNavbar />

        <Banner />

        <IntroSection
          dynamicContent={dynamicContent}
          linkClick={handleBrokerScroll}
        />
        <div className="w-100 d-flex justify-content-center align-items-center">
          <Button
            variant="primary"
            className={`${styles.priceButton}`}
            onClick={() =>
              window.open(process.env.REACT_APP_WHOLESALE_PRICEENGINE, "_blank")
            }
          >
            Price Your Scenario
          </Button>
        </div>
        <ProductSection />

        <LoanStatus />

        <div className={styles.guidelineSection}>
          <Container>
            <Row className={styles.guidelineRow}>
              <h2 className={styles.primaryh2}>Guidelines and Rate Sheets</h2>
              <p>
                At Sun West Mortgage, we pride ourselves in developing a wide
                range of programs that meet the dynamic needs of our customers.
                Our product versatility, technology-driven operations, and
                exceptional customer service allow our existing and prospective
                clients to maintain a competitive edge and increase their
                business opportunities in this challenging market. Click the
                link below to learn more about our program guidelines or login
                to learn more about pricing.
              </p>

              <Col md={6}>
                <div className={styles.accessRateDiv}>
                  <h3>Access Product Guidelines</h3>

                  <div className={styles.accessRateContent}>
                    <h6>
                      This information is intended for licensed mortgage
                      professionals only.
                      <br />
                      <br />
                      Are you a NMLS licensee?
                    </h6>

                    {wholesaleURL && (<Button
                      title="Yes, I am Licensed"
                      className={styles.accessButton}
                    >
                     <a
                        style={{ textDecoration: "none", color: "white" }}
                        href={wholesaleURL}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Yes, I am Licensed
                      </a>
                    </Button>)}

                    <small>
                      By clicking YES, you confirm you are a licensed
                      professional in the mortgage industry.
                    </small>
                  </div>
                </div>
              </Col>

              <Col md={6}>
                <div className={styles.rateSheetDiv}>
                  <h3>Rate sheets</h3>

                  <div className={styles.rateSheetContent}>
                    <SmallFormWrapper>
                      <div>
                        {session && session.token ? (
                          formShow === false ? (
                            <Loader />
                          ) : (
                            <Forms
                              formClass="ratesSmallForm"
                              buttonvalue="Access Rate Sheet"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                              handleSubmit={handleSubmit3}
                              onSubmit={onSubmit3}
                            >
                              {session.externalUser === false ? (
                                <Inputs
                                  register={register3}
                                  errors={errors3}
                                  type="text"
                                  name="companyCode"
                                  placeholder="Company Code*"
                                />
                              ) : null}
                              <div>
                                <label className={styles.customRatesLabel}>
                                  Please select a File Type *
                                </label>
                                <RadioButton
                                  register={register3}
                                  errors={errors3}
                                  value={["PDF", "EXCEL"]}
                                  name="fileType"
                                  selected={selectedFileType}
                                  changed={(ele) => fileTypeChange(ele)}
                                  type="radio"
                                />
                              </div>
                            </Forms>
                          )
                        ) : rateSheetLoading === false ? (
                          <>
                            <h4>SunSoft Login</h4>
                            <Forms
                              formClass={`${styles.form} smallForm`}
                              handleSubmit={handleSubmit2}
                              onSubmit={onSubmit2}
                            >
                              <Inputs
                                register={register2}
                                errors={errors2}
                                type="text"
                                name="username"
                                placeholder="SunSoft User ID*"
                              />

                              <Inputs
                                register={register2}
                                errors={errors2}
                                type="password"
                                name="password"
                                placeholder="SunSoft Password*"
                              />
                              <div className={styles.ratesSmallFont}>
                                <p>
                                  {" "}
                                  * If you do not have your SunSoft account
                                  information to download the rate sheet, please
                                  call your Client Relations Manager at (800)
                                  453-7884.
                                </p>
                              </div>
                            </Forms>
                          </>
                        ) : (
                          <Loader />
                        )}
                      </div>
                    </SmallFormWrapper>
                  </div>
                </div>
              </Col>
            </Row>

            <hr
              id="apply-scroll"
              style={{ borderTop: "2px solid #00AAE4", width: "90%" }}
            />
          </Container>

          <img
            alt=""
            src="https://resources.swmc.com/swmc-images/WholesaleLending/semiblueBg.svg"
            className={styles.rightbluebg}
          />

          <img
            alt=""
            src="https://resources.swmc.com/swmc-images/WholesaleLending/semiblueBg.svg"
            className={styles.leftbluebg}
          />

          <img
            alt=""
            src="https://resources.swmc.com/swmc-images/WholesaleLending/dot4.svg"
            className={styles.leftdot}
          />

          <img
            alt=""
            src="https://resources.swmc.com/swmc-images/WholesaleLending/eSquareMobile.svg"
            className={styles.eSquaretop}
          />
          <img
            alt=""
            src="https://resources.swmc.com/swmc-images/WholesaleLending/ellipseMobile.svg"
            className={styles.ellipseMobiletop}
          />

          <img
            alt=""
            src="https://resources.swmc.com/swmc-images/WholesaleLending/eSquareMobile.svg"
            className={styles.eSquaremid}
          />
          <img
            alt=""
            src="https://resources.swmc.com/swmc-images/WholesaleLending/ellipseMobile.svg"
            className={styles.ellipseMobilemid}
          />

          <img
            alt=""
            src="https://resources.swmc.com/swmc-images/WholesaleLending/eSquareMobile.svg"
            className={styles.eSquarebottom}
          />
        </div>

        <div className={styles.brokerformSection}>
          <Container>
            <Row className={styles.brokerAppRow} ref={trackButton}>
              <h2>Broker Application</h2>

              <h3>
                Thank you for your interest in our Wholesale Lending services.
              </h3>

              <FormWrapper>
                <div className="formarea">
                  {isLoading === false ? (
                    <Forms
                      formClass={styles.form}
                      style={{ display: "flex", justifyContent: "center" }}
                      handleSubmit={handleSubmit}
                      onSubmit={onSubmit}
                      buttonDisabled={!consent}
                    >
                      <Inputs
                        register={register}
                        errors={errors}
                        type="text"
                        name="Name"
                        placeholder="Name*"
                      />

                      <Inputs
                        register={register}
                        errors={errors}
                        type="text"
                        name="Company_Name"
                        placeholder="Company Name*"
                      />

                      <Inputs
                        register={register}
                        errors={errors}
                        type="text"
                        name="Company_Address"
                        placeholder="Company Address*"
                      />

                      <Row>
                        <Col sm={4}>
                          <Inputs
                            register={register}
                            errors={errors}
                            type="text"
                            name="City"
                            check={(evt) => ValidCity(evt)}
                            placeholder="City*"
                          />
                        </Col>

                        <Col sm={4}>
                          <Dropdown
                            register={register}
                            errors={errors}
                            values={States.States}
                            name="State"
                            placeholder="State*"
                            Change={(evt) => changeState(evt)}
                          />
                        </Col>

                        <Col sm={4}>
                          <Inputs
                            register={register}
                            errors={errors}
                            type="text"
                            name="Zipcode"
                            placeholder="Zipcode*"
                            check={(evt) => validZip(evt)}
                          />
                        </Col>
                      </Row>

                      <Inputs
                        register={register}
                        errors={errors}
                        type="email"
                        name="email"
                        placeholder="Email*"
                      />

                      <Inputs
                        register={register}
                        errors={errors}
                        type="text"
                        name="Telephone"
                        placeholder="Telephone*"
                      />

                      <Consent handleChange={handleConsentCheck} />
                    </Forms>
                  ) : (
                    <div
                      style={{
                        height: "150vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "0 auto",
                      }}
                    >
                      <Loader />
                    </div>
                  )}
                </div>
              </FormWrapper>
            </Row>
          </Container>

          <img
            alt=""
            src="https://resources.swmc.com/swmc-images/WholesaleLending/curves.svg"
            className={styles.rightcurve}
          />

          <img
            alt=""
            src="https://resources.swmc.com/swmc-images/WholesaleLending/semiblueDot.svg"
            className={styles.bluesemidot}
          />
        </div>

        <ContactUsSection />
        <CustomFooter />
      </section>

      <Modal
        size="lg"
        show={productShow}
        onHide={handleProductClose}
        className={styles.modal}
        title="Access Product Guidelines"
      >
        <h5 style={{ marginTop: "25px", textAlign: "center" }}>
          This information is intended for licensed mortgage professionals only.
          If you are not licensed, please exit now.
        </h5>
        <h5 style={{ marginTop: "25px", textAlign: "center" }}>
          Are you a NMLS licensee?
        </h5>
        <Row style={{ marginTop: "20px", justifyContent: "center" }}>
          <Button title="Yes, I am Licensed" className={styles.yesButton}>
            {" "}
            <a
              style={{ textDecoration: "none", color: "white" }}
              onClick={handleProductClose}
              href="https://www.allregs.com/tpl/XLink/Login?p1Id=cda88a50-9285-4543-a475-a6ecea113c68&p2Id=da973183-ff37-4a8f-8411-f667c80fa488&p3Id=617339&url=%3Fl2%3Dda973183-ff37-4a8f-8411-f667c80fa488&partialRegistration=True"
              target="_blank"
              rel="noreferrer"
            >
              Yes, I am Licensed
            </a>
          </Button>
          <Button
            title="No, I am not Licensed"
            className={styles.noButton}
            variant="secondary"
            onClick={handleProductClose}
          >
            No, I am not Licensed
          </Button>
        </Row>

        <Row>
          <p style={{ textAlign: "center", width: "100%" }}>
            By clicking <b>YES</b>, you confirm you are a licensed professional
            in the mortgage industry.
          </p>
        </Row>
      </Modal>
      {message !== " " && message !== "error" ? (
        <SweetAlert
          confirmBtnStyle={{
            textDecoration: "none",
          }}
          show={showModal}
          success
          title="Success!"
          onConfirm={closeSweetAlert}
          timeout={2000}
        >
          Mail sent Successfully
        </SweetAlert>
      ) : (
        <SweetAlert
          confirmBtnStyle={{
            textDecoration: "none",
          }}
          show={showModal}
          error
          title="Failed!"
          onConfirm={null}
          timeout={2000}
        >
          Failed to send mail,please try again in a while
        </SweetAlert>
      )}
      {session !== " " && session === "error" ? (
        <SweetAlert
          confirmBtnStyle={{
            textDecoration: "none",
          }}
          show={showModal}
          error
          title="Incorrect Credentials!"
          onConfirm={closeSweetAlert}
          timeout={3000}
        >
          Please try again with correct credentials
        </SweetAlert>
      ) : session !== " " && session === "connectivityError" ? (
        <SweetAlert
          confirmBtnStyle={{
            textDecoration: "none",
          }}
          show={showModal}
          error
          title="Network Error!"
          onConfirm={closeSweetAlert}
          timeout={3000}
        >
          Please try again later
        </SweetAlert>
      ) : null}

      {downloadError === true ? (
        <SweetAlert
          confirmBtnStyle={{
            textDecoration: "none",
          }}
          show={downloadError}
          error
          title="Download Failed"
          onConfirm={closeDownloadSweetAlert}
          timeout={3000}
        >
          {parse(errorMessage)}
        </SweetAlert>
      ) : null}
    </article>
  );
}

export default WholesaleLending;
