/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { removeNonAsciiSpecialCharacters, removeSpaces } from "../../utils";
import index from "./index.css";

const Inputs = (props) => {
  const cssModule = props.altStyling ? props.altStyling : index;
  // console.log('Inputs props:', props);

  const [value_, setValue] = useState(props.value);

  // console.log('hex: ', props.value, props.name)
  /** SWMC-1001: for certai fields, impose middleware-like actions,
   * as implemented in borrower module
   */
  const handleValueChange = (value, inputName) => {
    let newValue = removeSpaces(value);

    if (
      inputName.toLowerCase() === "name" ||
      inputName.toLowerCase() === "firstname" ||
      inputName.toLowerCase() === "lastname" ||
      inputName.toLowerCase() === "middle_name" ||
      inputName.toLowerCase() === "last_name" 
    ) {
      newValue = removeNonAsciiSpecialCharacters(newValue);
    }
    setValue(newValue);
  };

  return (
    <div>
      <label style={{ fontWeight: "600" }}>{props.label}</label>
      {props.type === "textarea" ? (
        <textarea
          className={cssModule.textBoxStyle}
          name={props.name}
          ref={props.register}
          placeholder={props.placeholder}
          rows="5"
          style={{ width: "100%" }}
        />
      ) : props.name.toLowerCase() === "zipcode" ||
        props.name.toLowerCase() === "city" ||
        props.name === "Loan_Number" ||
        props.name === "SSN" ? (
        <input
          className={cssModule.inputStyle}
          type={props.type}
          name={props.name}
          ref={props.register}
          placeholder={props.placeholder}
          onBlur={(ele) => props.check(ele)}
        />
      ) : props.name === "companyCode" ? (
        <input
          className={cssModule.inputStyle}
          type={props.type}
          name={props.name}
          ref={props.register}
          value={props.value}
          placeholder={props.placeholder}
          style={{ textTransform: "uppercase" }}
        />
      ) : props.name === "Date" ? (
        <input
          className={cssModule.inputStyle}
          type={props.type}
          name={props.name}
          ref={props.register}
          value={props.value}
          placeholder={props.placeholder}
          min={props.min}
        />
      ) : (
        <input
          className={cssModule.inputStyle}
          type={props.type}
          name={props.name}
          ref={props.register}
          //value={props.value}
          value={value_}
          placeholder={props.placeholder}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          onBlur={(e) => handleValueChange(e.target.value, props.name)}
        />
      )}
      {props.errors[props.name] && <p>{props.errors[props.name].message}</p>}
    </div>
  );
};
export default Inputs;
